::-webkit-scrollbar{
    width: .25rem;
    height: .25rem;
}
::-webkit-scrollbar-thumb{
    background: #AEABAB;
    opacity: 0.75;
    border-radius: 1rem;
}

::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 1rem;
}

::-webkit-scrollbar-corner{
    opacity: 0%;
}